<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$store.dispatch("users/checkUserLoggedIn");
    this.$store.dispatch("settings/getPriceEnable");
  }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>

<template>
    <div class="header">
        <sui-container fluid>
            <sui-grid divided="vertically">
                <div class="logoColumn">
                    <sui-grid-column :width="4" text-align="center">
                        <a :href='"https://heatpex.pl/"+language' target="_blank" class="headerLogo" title="heatpex.pl">
                            <img src="@/assets/images/heatpex-logo.svg" alt="Logo Heatpex"/>
                        </a>
                    </sui-grid-column>
                </div>
                <sui-grid-column
                        :width="9"
                        text-align="left"
                        class="computer tablet only"
                >
                    <div class="headerTitle">
                        <h2>{{ $t("header.headerTitle") }}</h2>
                        <p>{{ $t("header.subTitle") }}</p>
                    </div>
                </sui-grid-column>
                <div class="loginBtn">
                    <sui-grid-column class="badge-btn">
                        <div v-on:click="goToHome" class="home-button badge" target="_blank">
                            <img src="@/assets/images/home.svg" alt="Logo Heatpex"/>
                        </div>
                        <span v-if="isUserCertified" class="badge">
              <img
                      src="@/assets/images/certified_badge.svg"
                      alt="Certyfikowany instalator odznaka"
              />
              <img
                      src="@/assets/images/certified_text.svg"
                      alt="Certyfikowany instalator tekst"
              />
            </span>
                        <div class="btn" v-if="loggedIn && widerDropdown">
                            <sui-dropdown
                                    :text="loggedUser.firstname + ' ' + loggedUser.lastname"
                                    button
                                    pointing
                                    fluid
                            >
                                <sui-dropdown-menu>
                                    <sui-dropdown-item v-on:click="goToProfile"
                                    >{{ $t("header.profile") }}
                                    </sui-dropdown-item>
                                    <sui-dropdown-item v-if="isUserAdmin" v-on:click="goToPanel"
                                    >{{ $t("header.panel") }}
                                    </sui-dropdown-item>
                                    <sui-dropdown-item v-if="loggedUser.supervisor" v-on:click="gotoSupervisor"
                                    >{{ $t("header.supervisor") }}
                                    </sui-dropdown-item>
                                    <sui-dropdown-item v-on:click="logout"
                                    >{{ $t("header.logoutBtn") }}
                                    </sui-dropdown-item>
                                </sui-dropdown-menu>
                            </sui-dropdown>
                        </div>

                        <div class="btn" v-if="loggedIn && !widerDropdown">
                            <sui-dropdown :text="loggedUser.firstname" button pointing fluid>
                                <sui-dropdown-menu>
                                    <sui-dropdown-item v-on:click="goToProfile">
                                        {{ $t("header.profile") }}
                                    </sui-dropdown-item>
                                    <router-link to="/panel">
                                        <sui-dropdown-item
                                                v-if="isUserAdmin"
                                                v-on:click="goToPanel"
                                        >
                                            {{ $t("header.panel") }}
                                        </sui-dropdown-item>
                                    </router-link>
                                    <sui-dropdown-item v-on:click="logout">
                                        {{ $t("header.logoutBtn") }}
                                    </sui-dropdown-item>
                                </sui-dropdown-menu>
                            </sui-dropdown>
                        </div>
                        <div class="btn" v-if="!loggedIn">
                            <sui-button v-on:click="showModal" fluid>
                                {{ $t("header.loginBtn") }}
                            </sui-button>
                        </div>

                        <Switcher/>
                    </sui-grid-column>
                </div>
            </sui-grid>
        </sui-container>
        <LoginModal/>
        <MarketingAgreement />
    </div>
</template>

<script>
    import LoginModal from "@/components/modals/Login.vue";
    import MarketingAgreement from "@/components/modals/MarketingAgreement.vue";
    import Switcher from "@/components/Switcher.vue";
    import {mapGetters} from "vuex";
    import languages from "../consts/languages";

    export default {
        name: "Header",
        data() {
            return {
                openLoginModal: false,
                loggedIn: this.$store.state.users.isLoggedIn,
                loggedUser: this.$store.state.users.loggedUser,
                widerDropdown: true,
                language: languages.getLanguage(),
            };
        },
        components: {
            LoginModal,
            Switcher,
            MarketingAgreement
        },
        computed: {
            ...mapGetters({
                isUserAdmin: "users/isUserAdmin",
                isUserCertified: "users/isUserCertified"
            }),
            loggedInChanged() {
                return this.$store.state.users.isLoggedIn;
            },
            loggedUserChanged() {
                return this.$store.state.users.loggedUser;
            }
        },
        watch: {
            loggedInChanged(value) {
                this.loggedIn = value;
            },
            loggedUserChanged(value) {
                this.loggedUser = value;
            }
        },
        mounted() {
            let screenWidth = window.innerWidth;
            if (screenWidth < 1110) {
                this.widerDropdown = false;
            }
        },
        methods: {
            showModal: function () {
                this.$store.dispatch("users/toggleModal", true);
            },
            logout: function () {
                this.$store.state.orders.setOrders;
                this.$store.dispatch("users/logout").then(() => {
                    this.$store.dispatch("users/toggleModal", false);
                    this.$router.push({
                        name: "home"
                    });
                });
            },
            goToProfile: function () {
                this.$router.push({
                    name: "profile"
                });
            },
            goToHome: function () {
                this.$router.push({
                    name: "home"
                });
            },
            gotoSupervisor: function () {
                this.$router.push({
                    name: "supervisor"
                });
            },
            goToHeatpex: function (lang) {
                this.$router.push({
                    path: "https://heatpex.pl/"+lang,
                });
            },
            goToPanel: function () {
                this.$router.push({
                    name: "panel"
                });
            }
        }
    };
</script>

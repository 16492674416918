import Repository from "@/repository";

// states
const state = {
  unactiveUsers: [],
  users: [],
  projects: [],
  raports: [],
  loading: {
    unactiveUsers: false,
    users: false,
    projects: false,
    raports: false,
    tasks: false
  }
};

// getters
const getters = {};

// actions
const actions = {
  getUnactiveUsers({ commit }) {
    commit("SET_LOADING", {
      mode: "unactiveUsers",
      data: true
    });

    return Repository.get("/api/panel/unactive-users")
      .then(response => {
        commit("SET_LOADING", {
          mode: "unactiveUsers",
          data: false
        });

        commit("SET_UNACTIVE_USERS", response.data.data);

        return response.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  getUsers({ commit }) {
    commit("SET_LOADING", {
      mode: "users",
      data: true
    });

    return Repository.get("/api/panel/users")
      .then(response => {
        commit("SET_LOADING", {
          mode: "users",
          data: false
        });

        commit("SET_USERS", response.data.data);

        return response.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  getProjects({ commit }) {
    commit("SET_LOADING", {
      mode: "projects",
      data: true
    });

    return Repository.get("/api/panel/projects")
      .then(response => {
        commit("SET_LOADING", {
          mode: "projects",
          data: false
        });

        commit("SET_PROJECTS", response.data.data);

        return response.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  activateUser({ commit }, id) {
    commit("SET_LOADING", {
      mode: "unactiveUsers",
      data: true
    });

    return Repository.post("/api/panel/activate-user", { id: id })
      .then(response => {
        commit("SET_LOADING", {
          mode: "unactiveUsers",
          data: false
        });

        return response.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  getRaports({ commit }, params) {
    commit("SET_LOADING", {
      mode: "raports",
      data: true
    });

    return Repository.post("/api/panel/raports", params)
      .then(response => {
        commit("SET_LOADING", {
          mode: "raports",
          data: false
        });

        commit("SET_RAPORTS", response.data.data);

        return response.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  updateUserTasks({ commit }, params) {
    commit("SET_LOADING", {
      mode: "tasks",
      data: true
    });

    return Repository.post("/api/panel/user-tasks", params)
      .then(response => {
        commit("SET_LOADING", {
          mode: "tasks",
          data: false
        });

        return response.data.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  removeUserTasks({ commit }, params) {
    commit("SET_LOADING", {
      mode: "tasks",
      data: true
    });

    return Repository.post("/api/panel/remove-user-tasks", params)
      .then(response => {
        commit("SET_LOADING", {
          mode: "tasks",
          data: false
        });

        return response.data.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  },
  userDeactivate({ commit }, params) {
    commit("SET_LOADING", {
      mode: "users",
      data: true
    });

    return Repository.post("/api/panel/deactivate-user", params)
      .then(response => {
        commit("SET_LOADING", {
          mode: "users",
          data: false
        });

        return response.data.data;
      })
      .catch(error => {
        throw error.response.data.message;
      });
  }
};

// mutations
const mutations = {
  SET_LOADING(state, { mode, data }) {
    state.loading[mode] = data;
    state.loading = { ...state.loading };
  },
  SET_UNACTIVE_USERS(state, data) {
    state.unactiveUsers = data;
  },
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_PROJECTS(state, data) {
    state.projects = data;
  },
  SET_RAPORTS(state, data) {
    state.raports = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div class="lang-dropdown">
    <sui-dropdown
      fluid
      :options="options"
      :placeholder="$root.$i18n.locale"
      selection
      @input="onSelect($root.$i18n.locale)"
      v-model="$root.$i18n.locale"
    />
  </div>
</template>

<script>
import languages from "../consts/languages";
export default {
  name: "locale-changer",
  data() {
    return {
      options: languages.data()
    };
  },
  methods: {
    onSelect: function(lang) {
      languages.setLanguage(lang);
      location.reload();
    },
  },
};
</script>


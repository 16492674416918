import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import languages from "./consts/languages";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Homepage.vue")
    },
    {
      path: "/:lang(pl|en|ru)/:rest?",
      redirect: to => {
        const { params  } = to;
        // if( params.lang != localStorage.getItem('lang') ) {
        if( params.lang != languages.getLanguage() ) {
          languages.setLanguage(params.lang);
          location.reload();
        }
        else {
          return {path: params.rest || '/', meta: {reload: true}};
        }
      },
    },
    {
      path: "/result",
      name: "result",
      component: () => import("@/views/Resultpage.vue")
    },
    {
      path: "/supervisor",
      name: "supervisor",
      component: () => import("@/views/Supervisor.vue")
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("@/views/Privacy.vue"),

      },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
      beforeEnter(to, from, next) {
        store
            .dispatch("users/checkUserLoggedIn")
            .then(() => {
              next();
            })
            .catch(() => {
              next({ name: "home" });
            });
      }
    },
    {
      path: "/panel",
      name: "panel",
      component: () => import("@/views/Panel.vue"),
      beforeEnter(to, from, next) {

        if (store.getters["users/isUserAdmin"]) {
          next();
          return;
        }

        next({ name: "home" });
      }
    }
  ]
});

import Vue from "vue";
import Vuex from "vuex";
import roomsItem from "@/store/modules/rooms/item";
import usersItem from "@/store/modules/users/item";
import ordersItem from "@/store/modules/orders/item";
import panelItem from "@/store/modules/panel/item";
import settingsItem from "@/store/modules/settings/item";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    rooms: roomsItem,
    users: usersItem,
    orders: ordersItem,
    panel: panelItem,
    settings: settingsItem
  }
});

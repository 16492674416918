export default {
    data() {
        return [
            {
                key: "pl",
                text: "PL",
                value: "pl"
            },
            {
                key: "en",
                text: "EN",
                value: "en"
            },
            {
                key: "ru",
                text: "RU",
                value: "ru"
            },
            {
                key: "cs",
                text: "CZ",
                value: "cs"
            }
        ];
    },
    checkBrowserLang() {
        const locales = this.data().map(i => i.value);
        const browserLang = navigator.languages

        for (let i = 0; i < browserLang.length; i++) {
            const lang = browserLang[i].substring(0, 2);
            if (locales.includes(lang)) {
                return lang;
            }
        }
        return null;
    },

    getLanguage() {
        return localStorage.getItem("lang") || this.checkBrowserLang() || process.env.VUE_APP_I18N_LOCALE || "pl";
    }
    ,
    setLanguage(lang) {
        localStorage.setItem('lang', lang);
    }
}
import Repository from "@/repository";
import languages from "../../../consts/languages";

const lang = languages.getLanguage();

// states
const state = {
  errors: [],
  rooms: [],
  roomsList: [],
  roomsWithItems: [],
  height: 250,
  connectionParams: {},
  products: [],
    optionalProducts: [],
  priceEnable: false,
  orderData: {
    name: "",
    street: "",
    city: "",
    zipCode: "",
    investor: ""
  },
  order: [],
    muffChanged: false
};

// getters
const getters = {
  getRoomById: state => id => {
    return state.rooms.find(room => room.id === id);
  },
  getTotalSupply: state => {
    let sum = 0;
    state.roomsWithItems.forEach(val => {
      if (val.room_type === "supply") {
        sum += val.air_stream;
      }
    });
    return parseFloat(sum);
  },
  getTotalExhaust: state => {
    let sum = 0;
    state.roomsWithItems.forEach(val => {
      if (val.room_type === "exhaust") {
        sum += val.air_stream;
      }
    });
    return parseFloat(sum);
  },
  getPricesSum: state => {
    let sum = 0;
    state.products.forEach(val => {
      if (val.price_sum) {
        sum += parseFloat(val.price_sum.replace(",", "."));
      }
    });

    sum = sum.toFixed(2);

    return sum.toString().replace(".", ",");
  }
};

// actions
const actions = {
  fetchAll({ commit }) {
    return Repository.get(lang + "/api/public/rooms")
      .then(function(response) {
        commit("ROOMS", response.data.data);
        return response.data.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.data);
      });
  },
  addRoomToList({ commit }, data) {
    let name = data.name ? data.name : data.room.name;
    commit("ADD_ROOM_TO_LIST", {
      room_id: data.room.id,
      name: name,
      length: data.length || null,
      width: data.width || null,
      area: data.area,
      people: data.people || null,
      room_type: data.room.room_type,
      air_stream:
        data.room.room_type === "supply"
          ? data.people * data.room.air_amount
          : data.room.air_amount
    });
  },
  calculateRoomsItems({ commit }) {
    return Repository.post(
      lang + "/api/public/order-items/rooms",
      JSON.stringify({
        height: state.height,
        rooms: state.roomsList
      })
    )
      .then(function(response) {
        commit("UPDATE_ROOMS_ITEMS", response.data.data.rooms);
        commit("SET_PRODUCTS", []);
        commit(
          "UPDATE_CONNECTION_PARAMS",
          response.data.data.connection_params
        );
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data.errors);
      });
  },
  calculateConnectionsItems({ commit }, data) {
    return Repository.post(
      lang + "/api/public/order-items/connection",
      JSON.stringify(data)
    )
      .then(function(response) {
        commit("SET_PRODUCTS", response.data.data);
        commit("SET_OPTIONAL", response.data.optional);
        state.muffChanged = false;
        return response.data.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.data);
      });
  },
  updateRoomsList({ commit }, roomsList) {
    commit("UPDATE_ROOMS_LIST", roomsList);
  },
  setHeight({ commit }, height) {
    commit("SET_HEIGHT", height);
  },
  setOrderData({ commit }, data) {
    commit("SET_ORDER_DATA", data);
  },
  generateProductsList() {
    return Repository.post(lang + "/api/public/orders/pdf", {
      params: {
        products: state.products,
        order: state.order,
        rooms: state.roomsWithItems,
        connectionParams: state.connectionParams,
        priceEnable: state.priceEnable
      }
    }).then(response => {
      window.open(response.data);
    });
  },
  setProducts({ commit }, products) {
    commit("SET_PRODUCTS", products);
  },
  restoreOrder({ commit }, order) {
    commit("UPDATE_ROOMS_LIST", order.rooms);
    commit("SET_HEIGHT", order.height);
  },
  restorePDF(order) {
    window.open(order.html);
  },
  updateErrors({ commit }, errors) {
    commit("SET_ERRORS", errors);
  }
};

// mutations
const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },
  ROOMS(state, rooms) {
    state.rooms = rooms;
  },
  ADD_ROOM_TO_LIST(state, room) {
    state.roomsList.push(room);
  },
  UPDATE_ROOMS_LIST(state, roomsList) {
    state.roomsList = roomsList;
  },
  UPDATE_ROOMS_ITEMS(state, roomsWithItems) {
    state.roomsWithItems = roomsWithItems;
  },
  UPDATE_CONNECTION_PARAMS(state, connectionParams) {
    state.connectionParams = connectionParams;
  },
  SET_HEIGHT(state, height) {
    state.height = height;
  },
  SET_ORDER_DATA(state, data) {
    state.orderData = data;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_OPTIONAL(state, products) {
    state.optionalProducts = products;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

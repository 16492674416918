import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/index";
import SuiVue from "semantic-ui-vue";
import VueCookies from "vue-cookies";
import "semantic-ui-css/semantic.min.css";
import i18n from "@/plugins/i18n";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import languages from "./consts/languages";

const lang = languages.getLanguage();

// Charts
Vue.use(Chartkick);
Chartkick.use(Chart);
Chartkick.configure({ language: lang });

// es2015 module
Vue.use(VueCookies);

// set default config
VueCookies.config("7d");

// set global cookie
VueCookies.set("theme", "default");
VueCookies.set("hover-time", "1s");

// language change
i18n.locale = lang;

Vue.config.productionTip = false;
Vue.config.silent = true
Vue.use(SuiVue);
const app = new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
});

app.$mount("#app");

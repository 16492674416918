import axios from "axios";
import languages from "./consts/languages";
const baseDomain = "/";
// const baseDomain = "http://localhost:8050/";
const baseURL = `${baseDomain}`;

const lang = languages.getLanguage();

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Content-Language"] = lang;
axios.defaults.withCredentials = true;

export default axios.create({
  baseURL
});

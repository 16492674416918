import Repository from "@/repository";

// states
const state = {
  priceEnable: false,
  currency: "PLN"
};

// getters
const getters = {};

// actions
const actions = {
  getPriceEnable({ commit }) {
    return Repository.get("/api/price-enable").then(function(response) {
      commit("SET_PRICE_ENABLE", response.data.data);
      commit("SET_CURRENCY", response.data.currency);
      return response.data;
    });
  }
};

// mutations
const mutations = {
  SET_PRICE_ENABLE(state, data) {
    state.priceEnable = data;
  },
  SET_CURRENCY(state, data) {
    state.currency = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div>
    <sui-modal v-model="showLoginModal" :size="modalSize">
      <sui-modal-content v-show="!registerModal">
        <div class="modal-top-bar">
          <div class="text">{{ $t("login.loginToSystem") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <form class="ui form" @keyup.enter="authenticate">
          <div class="field">
            <label>{{ $t("banner.login.username") }}</label>
            <input
              type="text"
              :placeholder="$t('banner.login.username')"
              v-model="username"
              maxlength="30"
            />
          </div>
          <div class="field">
            <label>{{ $t("banner.login.password") }}</label>
            <input
              type="password"
              :placeholder="$t('banner.login.password')"
              v-model="user.password"
              maxlength="30"
            />
          </div>
        </form>
        <div class="ui error message" v-if="isError">
          <p>{{ $t("login.invalid_credentials") }}</p>
        </div>
        <div class="ui loginButton">
          <div class="ui equal width grid">
            <div class="column center aligned">
              <sui-button
                negative
                v-on:click="authenticate"
                :disabled="!username || !user.password"
              >
                {{ $t("banner.login.loginBtn") }}
              </sui-button>
            </div>
          </div>
        </div>
        <div class="register-link" v-on:click="toggleRegisterModal">
          <span>{{ $t("banner.register.modalLink") }}</span>
        </div>
        <div class="register-link" v-on:click="togglePasswordResetModal">
          <span>{{ $t("banner.login.forgotPassword") }}</span>
        </div>
      </sui-modal-content>

      <!-- register -->
      <sui-modal-content v-if="registerModal" class="registerModal">
        <div class="modal-top-bar">
          <div class="text">{{ $t("login.registerToSystem") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <p>
          {{ $t("banner.register.maininfo") }}
        </p>
        <p>
          {{ $t("banner.register.rememberToCheck") }}
        </p>
        <form class="ui form">
          <div class="ui stackable two column grid">
            <div class="column">
              <div :class="'field ' + registerErrors.firstname">
                <label v-if="!isError">{{
                  $t("banner.register.firstname")
                }}</label>
                <label v-if="isError">{{
                  $t("banner.register.errors.firstname")
                }}</label>
                <input
                  type="text"
                  :placeholder="$t('banner.register.firstname')"
                  v-model="user.firstname"
                  @input="firstnameValidator(user.firstname)"
                />
              </div>
            </div>

            <div class="column">
              <div :class="'field ' + registerErrors.lastname">
                <label v-if="!isError">{{
                  $t("banner.register.lastname")
                }}</label>
                <label v-if="isError"
                  >{{ $t("banner.register.errors.lastname") }}
                </label>
                <input
                  type="text"
                  :placeholder="$t('banner.register.lastname')"
                  v-model="user.lastname"
                  @input="lastnameValidator(user.lastname)"
                />
              </div>
            </div>

            <div class="column">
              <div :class="'field ' + registerErrors.phone">
                <label v-if="!isError">{{ $t("banner.register.phone") }}</label>
                <label v-if="isError"
                  >{{ $t("banner.register.errors.phone") }}
                </label>
                <input
                  type="text"
                  :placeholder="$t('banner.register.phone')"
                  v-model="user.phone"
                  @input="phoneValidator(user.phone)"
                />
              </div>
            </div>

            <div class="column">
              <div :class="'field ' + registerErrors.email">
                <label v-if="!isError">{{ $t("banner.register.email") }}</label>
                <label v-if="isError"
                  >{{ $t("banner.register.errors.email") }}
                </label>
                <input
                  type="text"
                  :placeholder="$t('banner.register.email')"
                  v-model="user.email"
                  @input="emailValidator(user.email)"
                />
              </div>
            </div>
          </div>
          <div class="ui stackable one column grid">
            <div class="column">
              <div :class="'field ' + registerErrors.username">
                <label v-if="!isError">{{
                  $t("banner.register.username")
                }}</label>
                <label v-if="isError"
                  >{{ $t("banner.register.errors.username") }}
                </label>
                <input
                  type="text"
                  :placeholder="$t('banner.register.username')"
                  v-model="user.username"
                  @input="usernameValidator(user.username)"
                />
              </div>
            </div>
          </div>

          <div class="ui stackable two column grid">
            <div class="column" v-if="$root.$i18n.locale === 'pl'">
              <div class="field">
                <label v-if="!isError">
                  {{ $t("banner.register.voivodeship") }}
                </label>
                <label v-if="isError">
                  {{ $t("banner.register.errors.voivodeship") }}
                </label>
                <sui-dropdown
                  :placeholder="$t('banner.register.voivodeshipSelect')"
                  selection
                  :options="voivodeships"
                  v-model="user.voivodeship"
                />
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label v-if="!isError">
                  {{ $t("banner.register.userType") }}
                </label>
                <label v-if="isError">
                  {{ $t("banner.register.errors.userType") }}
                </label>
                <sui-dropdown
                  :placeholder="$t('banner.register.userTypeSelect')"
                  selection
                  :options="userTypes"
                  v-model="user.userType"
                  v-on:input="checkUserType"
                />
              </div>
            </div>
          </div>

          <div class="ui stackable one column grid" v-if="showNip">
            <div class="column">
              <div :class="'field ' + registerErrors.nip">
                <label v-if="!registerErrors.nip">{{
                  $t("banner.register.nip")
                }}</label>
                <label v-if="registerErrors.nip">{{
                  $t("banner.register.errors.nip")
                }}</label>
<!--                <input-->
<!--                  type="text"-->
<!--                  :placeholder="$t('banner.register.nip')"-->
<!--                  v-model="user.nip"-->
<!--                  @input="nipValidator(user.nip)"-->
<!--                />-->
                <input
                  type="text"
                  :placeholder="$t('banner.register.nip')"
                  v-model="user.nip"
                />
              </div>
            </div>
          </div>

          <div class="ui stackable two column grid">
            <div class="column">
              <div :class="'field ' + registerErrors.password">
                <label v-if="!isError && registerErrors.password !== 'error'">{{
                  $t("banner.register.password")
                }}</label>
                <label v-if="isError">{{
                  $t("banner.register.errors.password")
                }}</label>
                <label v-if="registerErrors.password === 'error'">{{
                  $t("banner.register.errors.shortPassword")
                }}</label>
                <input
                  type="password"
                  :placeholder="$t('banner.register.password')"
                  v-model="user.password"
                  @input="passwordValidator(user.password)"
                />
              </div>
            </div>
            <div class="column">
              <div :class="'field ' + secondPasswordError">
                <label v-if="secondPasswordError === 'error'">{{
                  $t("banner.register.errors.secondPassword")
                }}</label>
                <label v-else>{{ $t("banner.register.secondPassword") }}</label>
                <input
                  type="password"
                  :placeholder="$t('banner.register.secondPassword')"
                  v-model="secondPassword"
                  @input="passwordValidator(user.password)"
                  @focusout="passwordMatching"
                />
              </div>
            </div>
          </div>

            <div class="ui stackable one column grid">
                <div class="row">
                    <div class="column">
                        <label class="marketing-label" v-html="$t('banner.register.rodoCheckbox.first')">
                        </label>
                    </div>
                </div>
            </div>

          <div class="ui stackable one column grid">
            <div class="row marketing-label-checkbox">
              <div class="column">
                <div class="ui checkbox">
                  <input type="checkbox" v-model="user.rodo_checkbox.first" />
                  <label v-html="$t('banner.register.rodoCheckbox.second')">

                  </label>
                </div>
              </div>
            </div>
            <div class="row marketing-label-checkbox">
              <div class="column">
                <div class="ui checkbox">
                  <input type="checkbox" v-model="user.rodo_checkbox.second" />
                  <label v-html="$t('banner.register.rodoCheckbox.third')">

                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="column">
                <div class="ui checkbox">
                  <input type="checkbox" v-model="user.regulations_checkbox" />
                  <label>
                    {{ $t("banner.register.regulationsCheckbox") }}
                    <a
                      href="http://34.90.224.65/klauzule i regulaminy/4. REGULAMIN WYKORZYSTANIA DOKUMENTÓW GENEROWANYCH Z POMOCĄ KALKULATORA ....docx"
                      target="_blank"
                    >
                      {{ $t("banner.register.regulationsCheckboxLink") }}
                    </a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="ui negative message" v-if="registerError">
          <p>
            {{ $t("banner.register.errors.errorMessage") }}
          </p>
        </div>

        <div class="ui loginButton">
          <div class="ui equal width grid">
            <div class="column center aligned">
              <sui-button
                negative
                v-on:click="register"
                :disabled="
                  !user.username ||
                    !user.password ||
                    !user.firstname ||
                    !user.lastname ||
                    !user.email ||
                    !user.rodo_checkbox ||
                    !user.regulations_checkbox ||
                    !secondPassword ||
                    !phonevalidated ||
                    !passwordvalidated ||
                    !namevalidated ||
                    !emailvalidated
                "
              >
                {{ $t("banner.register.registerBtn") }}
              </sui-button>
            </div>
          </div>
        </div>
        <div class="register-link" v-on:click="toggleRegisterModal">
          <span>{{ $t("banner.register.modalLoginLink") }}</span>
        </div>
      </sui-modal-content>
    </sui-modal>
    <sui-modal v-model="showRegisteredModal" size="small">
      <sui-modal-content>
        <div class="modal-top-bar">
          <div class="text">{{ $t("banner.register.confirmation.head") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <div class="text">
          {{ $t("banner.register.confirmation.info") }}
        </div>
      </sui-modal-content>
    </sui-modal>
    <sui-modal v-model="resetPasswordModal" size="tiny">
      <sui-modal-content>
        <div class="modal-top-bar">
          <div class="text">{{ $t("banner.reset.head") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <form class="ui form" @keyup.enter="sendResetRequest">
          <div class="field">
            <label>{{ $t("banner.reset.label") }}</label>
            <input
              type="email"
              placeholder="Email"
              v-model="resetEmail"
              maxlength="50"
            />
          </div>
        </form>
        <div class="ui loginButton">
          <div class="ui equal width grid">
            <div class="column center aligned">
              <sui-button
                negative
                v-on:click="sendResetRequest"
                :disabled="!resetEmail"
              >
                {{ $t("banner.reset.resetButton") }}
              </sui-button>
            </div>
          </div>
        </div>
      </sui-modal-content>
    </sui-modal>
    <sui-modal v-model="resetPasswordModalInfo" size="small">
      <sui-modal-content>
        <div class="modal-top-bar">
          <div class="text">{{ $t("banner.reset.head") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <div class="text">
          {{ $t("banner.reset.info") }}
        </div>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
let newVar = {
  name: "LoginModal",
  data: function() {
    return {
      user: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        voivodeship: null,
        userType: null,
        nip: null,
        rodo_checkbox: {
            first: false,
            second: false
        },
        regulations_checkbox: false
      },
      modalSize: "mini",
      allowLogin: false,
      username: "",
      password: "",
      secondPassword: "",
      secondPasswordError: "",
      showLoginModal: this.$store.state.users.showModal,
      showNip: false,
      nipvalidated: false,
      phonevalidated: false,
      passwordvalidated: false,
      namevalidated: false,
      emailvalidated: false,
      registerError: false,
      registerModal: 0,
      isError: false,
      registerErrors: [],
      showRegisteredModal: false,
      resetEmail: "",
      resetPasswordModal: false,
      resetPasswordModalInfo: false,
      userTypes: [
        {
          text: this.$t("banner.register.wholesaler"),
          value: "Hurtownia instalacyjna"
        },
        {
          text: this.$t("banner.register.installer"),
          value: "Instalator"
        },
        {
          text: this.$t("banner.register.userPrivate"),
          value: "Osoba prywatna"
        }
      ],
      voivodeships: [
        {
          text: "dolnośląskie",
          value: "dolnoslaskie"
        },
        {
          text: "kujawsko-pomorskie",
          value: "kujawsko-pomorskie"
        },
        {
          text: "lubelskie",
          value: "lubelskie"
        },
        {
          text: "lubuskie",
          value: "lubuskie"
        },
        {
          text: "mazowieckie",
          value: "mazowieckie"
        },
        {
          text: "małopolskie",
          value: "malopolskie"
        },
        {
          text: "opolskie",
          value: "opolskie"
        },
        {
          text: "podkarpackie",
          value: "podkarpackie"
        },
        {
          text: "podlaskie",
          value: "podlaskie"
        },
        {
          text: "pomorskie",
          value: "pomorskie"
        },
        {
          text: "warmińsko-mazurskie",
          value: "warmińsko-mazurskie"
        },
        {
          text: "wielkopolskie",
          value: "wielkopolskie"
        },
        {
          text: "zachodniopomorskie",
          value: "zachodniopomorskie"
        },
        {
          text: "łódzkie",
          value: "lodzkie"
        },
        {
          text: "śląskie",
          value: "slaskie"
        },
        {
          text: "świętokrzyskie",
          value: "swietokrzyskie"
        }
      ]
    };
  },
  computed: {
    toggleModalChanged() {
      return this.$store.state.users.showModal;
    }
  },
  watch: {
    toggleModalChanged(value) {
      this.showLoginModal = value;
    },
    showLoginModal: function(val) {
      this.$store.dispatch("users/toggleModal", val);
    }
  },
  methods: {
    passwordMatching: function() {
      this.user.password !== this.secondPassword
        ? (this.secondPasswordError = "error")
        : (this.secondPasswordError = "");
    },
    sendResetRequest: function() {
      this.$store
        .dispatch("users/resetPassword", {
          email: this.resetEmail
        })
        .then(() => {
          this.resetPasswordModalInfo = true;
          this.resetPasswordModal = false;
          this.clearData();
        });
    },
    authenticate: function() {
      this.$store
        .dispatch("users/login", {
          username: this.username,
          password: this.user.password
        })
        .then(() => {
          this.showLoginModal = false;
          this.$store.dispatch("users/toggleModal", false);
          this.username = "";
          this.password = "";
          this.isError = false;
          this.clearData();
        })
        .catch(() => {
          this.isError = true;
        });
    },
    toggleRegisterModal: function() {
      this.username = "";
      this.password = "";
      this.isError = false;
      this.registerModal
        ? (this.modalSize = "mini")
        : (this.modalSize = "small");
      this.registerModal = !this.registerModal;
    },
    togglePasswordResetModal: function() {
      this.showLoginModal = false;
      this.resetPasswordModal = true;
    },
    register: function() {
      this.$store
        .dispatch("users/register", this.user)
        .then(() => {
          this.toggleRegisterModal;
          this.modalSize = "mini";
          this.showLoginModal = false;
          this.showRegisteredModal = true;
          this.clearData();
        })
        .catch(response => {
          this.onRegisterError(response);
        });
    },
    checkField(field) {
      switch (field) {
        case "email":
          if (this.registerErrors.email) {
            return "error";
          }
          break;
        case "phone":
          if (this.registerErrors.phone) {
            return "error";
          }
          break;
        case "firstname":
          if (this.registerErrors.firstname) {
            return "error";
          }
          break;
        case "lastname":
          if (this.registerErrors.lastname) {
            return "error";
          }
          break;
        case "password":
          if (this.registerErrors.password) {
            return "error";
          }
          break;
        case "secondPassword":
          if (this.registerErrors.secondPassword) {
            return "error";
          }
          break;
        case "username":
          if (this.registerErrors.username) {
            return "error";
          }
          break;

        default:
          break;
      }
      if (this.registerErrors.field) {
        return "error";
      }
    },
    closeModal: function() {
      this.showLoginModal = false;
      this.registerModal = false;
      this.showRegisteredModal = false;
      this.resetPasswordModal = false;
      this.resetPasswordModalInfo = false;
      this.modalSize = "mini";
      this.clearData();
    },
    clearData: function() {
      this.user = {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        voivodeship: null,
        userType: null,
        nip: null,
        rodo_checkbox: false,
        regulations_checkbox: false
      };
      this.secondPassword = "";
    },
    checkUserType: function() {
      if (this.user.userType === this.userTypes[0].value || this.user.userType === this.userTypes[1].value) {
        this.showNip = true;
        this.nipvalidated = false;
      } else {
        this.nipvalidated = true;
        this.showNip = false;
      }
    },
    nipValidator: function(nip) {
      this.user.nip = this.user.nip.replace(/,/, "");
      this.user.nip = this.user.nip.replace(/\./, "");
      this.user.nip = this.user.nip.replace(/-/, "");
      this.user.nip = this.user.nip.replace(/\+/, "");
      !isNaN(nip) && nip.toString().length == 10
        ? (this.nipvalidated = true)
        : (this.nipvalidated = false);
      this.user.nip.toString().length > 9 &&
      this.user.nip.toString().length < 11 &&
      !isNaN(this.user.nip)
        ? (this.registerErrors.nip = "")
        : (this.registerErrors.nip = "error");
    },
    phoneValidator: function(number) {
      this.user.phone = this.user.phone.replace(/,/, "");
      this.user.phone = this.user.phone.replace(/\./, "");
      this.user.phone = this.user.phone.replace(/-/, "");
      !isNaN(number) && number.toString().length > 8
        ? (this.phonevalidated = true)
        : (this.phonevalidated = false);
      number.length > 8 && number.length < 13 && !isNaN(number)
        ? (this.registerErrors.phone = "")
        : (this.registerErrors.phone = "error");
    },
    emailValidator: function(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      re.test(String(email).toLowerCase())
        ? (this.emailvalidated = true)
        : (this.emailvalidated = false);
      this.emailvalidated
        ? (this.registerErrors.email = "")
        : (this.registerErrors.email = "error");
    },
    passwordValidator: function(password) {
      password.toString().length > 5 && password == this.secondPassword
        ? (this.passwordvalidated = true)
        : (this.passwordvalidated = false);
      password.toString().length > 5
        ? (this.registerErrors.password = "")
        : (this.registerErrors.password = "error");
    },
    firstnameValidator: function(name) {
      var re = /^[a-zA-Z]+$/;
      name.toString().length > 1 &&
      name.toString().length < 30 &&
      isNaN(name) &&
      re.test(name)
        ? (this.namevalidated = true)
        : (this.namevalidated = false);
      name.toString().length > 1 &&
      name.toString().length < 30 &&
      isNaN(name) &&
      re.test(name)
        ? (this.registerErrors.firstname = "")
        : (this.registerErrors.firstname = "error");
    },
    lastnameValidator: function(lastname) {
      var re = /^[a-zA-Z]+$/;
      lastname.toString().length > 1 &&
      lastname.toString().length < 35 &&
      isNaN(lastname) &&
      re.test(lastname)
        ? (this.lastnamevalidated = true)
        : (this.lastnamevalidated = false);
      lastname.toString().length > 1 &&
      lastname.toString().length < 35 &&
      isNaN(lastname) &&
      re.test(lastname)
        ? (this.registerErrors.lastname = "")
        : (this.registerErrors.lastname = "error");
    },
    usernameValidator: function(username) {
      username.toString().length > 1
        ? (this.namevalidated = true)
        : (this.namevalidated = false);
      username.toString().length < 2
        ? (this.registerErrors.username = "error")
        : (this.registerErrors.username = "");
    },
    onRegisterError: function(errors) {
      this.registerError = true;
      this.registerErrors.message = errors;
    }
  }
};
export default newVar;
</script>

<style>
.marketing-label {
    color: rgba(0,0,0,.87);
    font-size: 11px !important;
}
.marketing-label-checkbox {
    margin-left: 20px !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
</style>
import Repository from "@/repository";
import roomsItem from "@/store/modules/rooms/item";
import usersItem from "@/store/modules/users/item";

// states
const state = {
  orders: [],
  projectName: "",
  street: "",
  city: "",
  postcode: "",
  investor: "",
  canSaveOrder: false,
  loading: false
};

// getters
const getters = {
  getProjectName: state => {
    return state.projectName;
  }
};

// actions
const actions = {
  saveProject({ commit }, projectData) {
    commit("SET_LOADING", true);
    return Repository.post("/api/public/orders", {
      name: state.projectName,
      street: projectData.street,
      city: projectData.city,
      zipCode: projectData.zipCode,
      investor: projectData.investor,
      pdf: projectData.pdf ? projectData.pdf : false,
      height: roomsItem.state.height,
      client: usersItem.state.loggedUser,
      rooms: roomsItem.state.roomsList,
      products: roomsItem.state.products,
      priceEnable: roomsItem.state.priceEnable,
      params: {
        products: roomsItem.state.products,
        order: roomsItem.state.order,
        rooms: roomsItem.state.roomsWithItems,
        connectionParams: roomsItem.state.connectionParams
      }
    })
      .then(response => {
        commit("SET_LOADING", false);
        return response.data;
      })
      .catch(() => {});
  },
  getUserOrders({ commit }) {
      commit("SET_LOADING", true);
    let user = usersItem.state.loggedUser;
    return Repository.get("/api/public/user/orders", {
      params: {
        user
      }
    }).then(response => {
        commit("SET_LOADING", false);
      commit("SET_ORDERS", response.data.data);
      return response.data.data;
    });
  },
  setProjectName({ commit }, projectName) {
    commit("SET_PROJECTNAME", projectName);
  },
  setStreet({ commit }, street) {
    commit("SET_STREET", street);
  },
  setCity({ commit }, city) {
    commit("SET_CITY", city);
  },
  setPostcode({ commit }, postcode) {
    commit("SET_POSTCODE", postcode);
  },
  setInvestor({ commit }, investor) {
    commit("SET_INVESTOR", investor);
  },
  setOrders({ commit }, orders) {
    commit("SET_ORDERS", orders);
  },
  deleteOrder({ commit }, order) {
    return Repository.post("/api/public/orders/delete", {
      order: order
    })
      .then(function(response) {
        commit("REMOVE_ORDER", order);
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data.errors);
      });
  }
};

// mutations
const mutations = {
  SET_LOADING(state, status) {
    state.loading = status;
  },
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_PROJECTNAME(state, projectName) {
    state.projectName = projectName;
  },
  SET_STREET(state, street) {
    state.street = street;
  },
  SET_CITY(state, city) {
    state.city = city;
  },
  SET_POSTCODE(state, postcode) {
    state.postcode = postcode;
  },
  SET_INVESTOR(state, investor) {
    state.investor = investor;
  },
  REMOVE_ORDER(state, order) {
    let index = state.orders.findIndex(item => item.order_id === order);
    state.orders.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

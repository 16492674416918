<template>
    <div>
        <sui-modal v-model="showModal" size="small">
            <sui-modal-content>
                <div class="modal-top-bar">
                    <div class="text">{{ $t("marketing.modal.header") }}</div>
                    <div class="cross" v-on:click="closeModal">x</div>
                </div>
                <div class="text">
                    {{ $t("marketing.modal.text") }}

                    <div class="row marketing-label-checkbox">
                        <div class="column">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="user.rodo_checkbox.first" />
                                <label v-html="$t('banner.register.rodoCheckbox.second')">

                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row marketing-label-checkbox">
                        <div class="column">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="user.rodo_checkbox.second" />
                                <label v-html="$t('banner.register.rodoCheckbox.third')">

                                </label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom: 20px;">
                        <router-link to="/privacy" target="_blank">
                            {{ $t("marketing.modal.link") }}
                        </router-link>
                    </div>
                    <sui-button
                        @click="setAgreement(false)"
                    >
                        {{ $t("marketing.modal.no") }}
                    </sui-button>
                    <sui-button
                        negative
                        @click="setAgreement(true)"
                    >
                        {{ $t("marketing.modal.yes") }}
                    </sui-button>
                </div>
            </sui-modal-content>
        </sui-modal>
    </div>
</template>

<script>
import Repository from "@/repository";

export default {
    name: "MarketingAgreement",
    data() {
        return {
            showModal: false,
            loggedIn: this.$store.state.users.isLoggedIn,
            loggedUser: this.$store.state.users.loggedUser,
            user: {
                rodo_checkbox: {
                    first: false,
                    second: false
                }
            }
        }
    },
    methods: {
        closeModal: function () {
            this.showModal = false;
        },
        setAgreement: function (agree) {
            Repository.post(this.$i18n.locale + "/api/marketing-new", {
                'agree': agree,
                'first': this.user.rodo_checkbox.first,
                'second': this.user.rodo_checkbox.second
            }).then(() => {
                    this.showModal = false;
                }
            );
        }
    },
    computed: {
        loggedInChanged() {
            return this.$store.state.users.isLoggedIn;
        },
        loggedUserChanged() {
            return this.$store.state.users.loggedUser;
        }
    },
    watch: {
        loggedInChanged(value) {
            this.loggedIn = value;
        },
        loggedUserChanged(value) {
            this.loggedUser = value;
            if (this.$route.name !== 'privacy' && this.$i18n.locale === "pl" && this.loggedIn && this.loggedUser && this.loggedUser.new_marketing_agreement_email === null && this.loggedUser.new_marketing_agreement_phone === null) {
                this.showModal = true;
            }
        }
    },
}
</script>

<template>
  <div class="footer">
    <sui-container text-align="center">
      <sui-grid divided="vertically" :columns="5">
        <div class="stackable doubling row">
          <sui-grid-column class="four wide tablet two wide computer">
            {{ $t("footer.companyName") }}
          </sui-grid-column>
          <sui-grid-column
            class="six wide tablet five wide computer four wide large screen four wide widescreen column"
          >
            <sui-icon name="map marker alternate" class="icon" />
            {{ $t("footer.street") }}
          </sui-grid-column>
          <sui-grid-column
            class="six wide tablet three wide computer three wide large screen three wide widescreen column"
          >
            <sui-icon name="phone" class="icon" />
            {{ $t("footer.phone") }}
          </sui-grid-column>
          <sui-grid-column class="nine wide tablet two wide computer email">
            <sui-icon name="envelope" class="icon" />
            {{ $t("footer.email") }}
          </sui-grid-column>
          <sui-grid-column class="right floated">
            <router-link to="privacy">{{ $t("footer.privacy") }}</router-link>
          </sui-grid-column>
        </div>
      </sui-grid>
    </sui-container>
    <div class="cookiebar" v-if="!cookieBarVisibility">
      <div class="ui center aligned container">
        <div class="ui centered grid">
          <div
            class="sixteen wide mobile eleven wide computer column column text"
          >
            <p>{{ $t("footer.cookies.text") }}</p>
          </div>
          <div class="sixteen wide mobile four wide computer column">
            <button class="ui button" @click="cookiesAgreement">
              {{ $t("footer.cookies.button") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data: function() {
    return {
      cookiesAgree: null,
      cookieBarVisibility: false
    };
  },
  created() {
    this.cookiesCheck();
  },
  watch: {
    cookiesAgree: function() {
      this.cookiesAgree
        ? (this.cookieBarVisibility = true)
        : (this.cookieBarVisibility = false);
    }
  },
  methods: {
    cookiesAgreement: function() {
      window.$cookies.set("cookiesAgreement", "true", 60 * 60 * 24 * 30);
      this.cookiesAgree = true;
    },
    cookiesCheck: function() {
      var cookies = window.$cookies.get("cookiesAgreement");
      this.cookieBarVisibility = cookies;
    }
  }
};
</script>

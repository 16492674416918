import Repository from "@/repository";

// states
const state = {
  errors: [],
  loggedUser: {
    roles: []
  },
  isLoggedIn: false,
  showModal: false,
  certified: false,
  isAdmin: false
};

// getters
const getters = {
  isUserAdmin: state => state.isAdmin,
  isUserCertified: state => state.certified
};

// actions
const actions = {
  login({ commit }, user) {
    return Repository.post("/login", user)
      .then(function(response) {
        commit("IS_AUTHENTICATED", response.data);
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data.error);
        throw error.response.data.error;
      });
  },
  logout({ commit }) {
    return Repository.post("/logout")
      .then(function(response) {
        commit("LOGOUT", response.data);
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.data);
      });
  },
  edit({ commit }, user) {
    return Repository.put("/api/public/users", user)
      .then(function() {})
      .catch(function(error) {
        commit("SET_ERRORS", error.data);
      });
  },
  resetPassword({ commit }, email) {
    return Repository.post("/api/public/reset-password", email).then(function(
      response
    ) {
      commit("SET_ERRORS", []);
      return response.data;
    });
  },
  register({ commit }, user) {
    return Repository.post("/api/public/users", user)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data.message);
        throw error.response.data.message;
      });
  },
  checkUserLoggedIn({ commit }) {
    return Repository.get("/api/public/users/logged-in")
      .then(function(response) {
        commit("IS_AUTHENTICATED", response.data.data);
        return response.data;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data.error);

        throw error.response.data.error;
      });
  },
  toggleModal({ commit }, value) {
    commit("TOGGLE_MODAL", value);
  }
};

// mutations
const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },
  IS_AUTHENTICATED(state, user) {
    state.loggedUser = user;
    state.isLoggedIn = true;
    state.isAdmin = user && user.roles && user.roles.includes("ROLE_ADMIN");
    state.certified = user && user.certified;
  },
  LOGOUT(state) {
    state.loggedUser = {};
    state.isLoggedIn = false;
  },
  TOGGLE_MODAL(state, show) {
    state.showModal = show;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
